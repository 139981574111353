import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import LazyLoad from "react-lazyload";

import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import Footer from "./components/Footer/Footer";

// import FhaLoanGuide from "./RootPage/Pages/FhaLoanGuide/FhaLoanGuide";
// import Tools from "./RootPage/Pages/Tools/Tools";
// import Articles from "./RootPage/Pages/Articles/Articles";
// import Start from "./RootPage/Pages/Start/Start";
// import Page404 from "./RootPage/page404/Page404";
// import HomePageLead from "./RootPage/HomePageLead/HomePageLead";
// import Videos from "./RootPage/Pages/Videos/Videos";

import "./App.css";

const FhaLoanGuide = lazy(() =>
  import("./RootPage/Pages/FhaLoanGuide/FhaLoanGuide")
);
const VALoanGuide = lazy(() =>
  import("./RootPage/Pages/VALoanGuide/VALoanGuide")
);
const FannieMaeLoan = lazy(() =>
  import("./RootPage/Pages/FannieMaeLoan/FannieMaeLoan")
);
const Tools = lazy(() => import("./RootPage/Pages/Tools/Tools"));
const Articles = lazy(() => import("./RootPage/Pages/Articles/Articles"));
const Start = lazy(() => import("./RootPage/Pages/Start/Start"));
const Page404 = lazy(() => import("./RootPage/page404/Page404"));
const HomePageLead = lazy(() => import("./RootPage/HomePageLead/HomePageLead"));
const Videos = lazy(() => import("./RootPage/Pages/Videos/Videos"));
const ContactUs = lazy(() => import("./RootPage/Pages/ContactUs/ContactUs"));
const About = lazy(() => import("./RootPage/Pages/About/About"));
const SiteMap = lazy(() => import("./RootPage/Pages/SiteMap/SiteMap"));
const Privacy = lazy(() => import("./RootPage/Pages/Other/Privacy"));
const Disclaimer = lazy(() => import("./RootPage/Pages/Other/Disclaimer"));
const Terms = lazy(() => import("./RootPage/Pages/Other/Terms"));
const Copyrights = lazy(() => import("./RootPage/Pages/Other/Copyrights"));
const Licenses = lazy(() => import("./RootPage/Pages/Other/Licenses"));
const Faq = lazy(() => import("./RootPage/Pages/Faq/Faq"));
const ScheduleCallPage = lazy(() =>
  import("./RootPage/Pages/ScheduleCallPage/ScheduleCallPage")
);

const HowFannieMaesNewCreditScoreRuleCanHelpYou = lazy(() =>
  import(
    "./RootPage/Pages/Articles/pages/HowFannieMaesNewCreditScoreRuleCanHelpYou"
  )
);
const WhatAreTheProsAndConsOfRefinancingYourHome = lazy(() =>
  import(
    "./RootPage/Pages/Articles/pages/WhatAreTheProsAndConsOfRefinancingYourHome"
  )
);
const WhatVeteransShouldKnow = lazy(() =>
  import("./RootPage/Pages/Articles/pages/WhatVeteransShouldKnow")
);
const ComparingFHAAndConventionalHomeLoans = lazy(() =>
  import("./RootPage/Pages/Articles/pages/ComparingFHAAndConventionalHomeLoans")
);
const FHALoanRefinancingOptions = lazy(() =>
  import("./RootPage/Pages/Articles/pages/FHALoanRefinancingOptions")
);
const FhaCalculator = lazy(() =>
  import("./RootPage/Pages/FhaCalculator/FhaCalculator")
);
const FhaAffordabilityCalculator = lazy(() =>
  import(
    "./RootPage/Pages/FhaAffordabilityCalculator/FhaAffordabilityCalculator"
  )
);

function App() {
  const { pathname } = useLocation();

  return (
    <>
      <ScrollToTop>
        <Suspense fallback={<LoadingScreen />}>
          <Switch>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
            <Route path="/" exact render={(props) => <HomePageLead />} />
            {/* <Route
              path="/lead-generator"
              exact
              render={(props) => <HomePageLead />}
            /> */}
            <Route
              path="/fha-loan-guide"
              exact
              render={(props) => <FhaLoanGuide />}
            />
            <Route path="/va-loans" exact render={(props) => <VALoanGuide />} />
            <Route
              path="/fannie-mae"
              exact
              render={(props) => <FannieMaeLoan />}
            />
            <Route path="/tools" exact render={(props) => <Tools />} />
            <Route path="/articles" exact render={(props) => <Articles />} />
            <Route
              path="/articles/how-fannie-maes-new-credit-score-rule-can-help-you"
              exact
              render={(props) => <HowFannieMaesNewCreditScoreRuleCanHelpYou />}
            />
            <Route
              path="/articles/what-are-the-pros-and-cons-of-refinancing-your-home"
              exact
              render={(props) => <WhatAreTheProsAndConsOfRefinancingYourHome />}
            />
            <Route
              path="/articles/fha-vs-va-loans"
              exact
              render={(props) => <WhatVeteransShouldKnow />}
            />
            <Route
              path="/articles/fha-vs-conventional"
              exact
              render={(props) => <ComparingFHAAndConventionalHomeLoans />}
            />
            <Route
              path="/articles/fha-loan-refinance"
              exact
              render={(props) => <FHALoanRefinancingOptions />}
            />
            <Route path="/videos" exact render={(props) => <Videos />} />
            <Route path="/start" exact render={(props) => <Start />} />
            <Route path="/contact" exact render={(props) => <ContactUs />} />
            <Route path="/about" exact render={(props) => <About />} />
            <Route path="/privacy" exact render={(props) => <Privacy />} />
            <Route
              path="/disclaimers"
              exact
              render={(props) => <Disclaimer />}
            />
            <Route path="/terms" exact render={(props) => <Terms />} />
            <Route path="/copyright" exact render={(props) => <Copyrights />} />
            <Route path="/licenses" exact render={(props) => <Licenses />} />
            <Route path="/faqs" exact render={(props) => <Faq />} />
            <Route
              path="/get-schedule"
              exact
              render={(props) => <ScheduleCallPage />}
            />
            <Route
              path="/tools/fha-calculator"
              exact
              render={(props) => <FhaCalculator />}
            />
            <Route
              path="/tools/fha-affordability-calculator"
              exact
              render={(props) => <FhaAffordabilityCalculator />}
            />
            <Route path="/sitemap" exact render={(props) => <SiteMap />} />
            <Route path="*" exact={true} component={Page404} />
          </Switch>
        </Suspense>
        <LazyLoad height={200} once>
          <Footer />
        </LazyLoad>
      </ScrollToTop>
    </>
  );
}

export default App;
