import React from "react";

import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "./Footer.css";

function Footer() {
  const { t } = useTranslation();
  return (
    <section className="footer-index">
      <div className="container py-3">
        <div className="row mt-5">
          <div className="col-sm-3">
            <ul className="list-unstyled">
              <h5 className="footer-head-text-style">{t("footer.head_1")}</h5>
              <hr className="footer-hr" />
              <li>
                <a href="/fha-loan-guide#what-fha-loan">{t("footer.txt_1")}</a>
              </li>
              <li>
                <a href="/va-loans">{t("footer.txt_2")}</a>
              </li>
              <li>
                <a href="/fannie-mae">{t("footer.txt_3")}</a>
              </li>
            </ul>
          </div>
          <div className="col-sm-5">
            <ul className="list-unstyled">
              <h5 className="footer-head-text-style">{t("footer.head_2")}</h5>
              <hr className="footer-hr" />
              <li>
                <a href="/articles/how-fannie-maes-new-credit-score-rule-can-help-you">
                  {t("how_fannie_maes_new_credit_score.head")}
                </a>
              </li>
              <li>
                <a href="/articles/what-are-the-pros-and-cons-of-refinancing-your-home">
                  {t(
                    "what_are_the_pros_and_cons_of_refinancing_your_home.head"
                  )}
                </a>
              </li>
              <li>
                <a href="/articles/fha-vs-va-loans">
                  {t("what_veterans_should_know.head")}
                </a>
              </li>
              <li>
                <a href="/articles/fha-vs-conventional">
                  {t("comparing_fHA_and_conventional_home_loans.head")}
                </a>
              </li>
              <li>
                <a href="/articles/fha-loan-refinance">
                  {t("FHA_loan_refinancing_options.head")}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-4">
            <ul className="list-unstyled">
              <h5 className="footer-head-text-style">{t("footer.head_3")}</h5>
              <hr className="footer-hr" />
              <li>
                <a href="/faqs">{t("footer.txt_4")}</a>
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ borderTop: "1px solid #FFFFFF" }} />
        <div className="row justify-content-md-center">
          <div className="col-sm-12">
            <Navbar
              className="footer-nav-bar"
              collapseOnSelect
              expand="lg"
              bg="transparent"
              variant="dark"
            >
              <Container>
                <Navbar.Brand href="/">
                  <img
                    src="/images/logo.webp"
                    height="55"
                    className="d-inline-block align-top"
                    alt="logo"
                  />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto"></Nav>
                  <Nav>
                    <Nav.Link href="/about">{t("footer.txt_5")}</Nav.Link>
                    <Nav.Link href="/sitemap">{t("footer.txt_6")}</Nav.Link>
                    <Nav.Link href="/privacy">{t("footer.txt_7")}</Nav.Link>
                    <Nav.Link href="/disclaimers">{t("footer.txt_8")}</Nav.Link>
                    <Nav.Link href="/terms">{t("footer.txt_9")}</Nav.Link>
                    <Nav.Link href="/copyright">{t("footer.txt_10")}</Nav.Link>
                    <Nav.Link href="/licenses">{t("footer.txt_11")}</Nav.Link>
                    <Nav.Link href="/contact">{t("footer.txt_18")}</Nav.Link>
                    <Button href="/start" variant="outline-info">
                      {t("footer.txt_16")}
                    </Button>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
          <hr style={{ borderTop: "1px solid #FFFFFF" }} />
          <div className="col-sm-12">
            <ul className="list-unstyled">
              <p className="footer-p-tag">{`${t(
                "footer.txt_17"
              )} ${new Date().getFullYear()} ${t("footer.copyrights")}`}</p>
            </ul>
          </div>
        </div>
        <hr style={{ borderTop: "1px solid #FFFFFF" }} />
        <div className="row">
          <div className="col-sm-4">
            <ul className="list-unstyled">
              <p className="footer-p-tag">
                {t("footer.txt_12")}
                <br />
                {t("footer.txt_13")}
              </p>
              <a href="tel:+1 (844) 344-1722">+1 (844) 344-1722</a>
            </ul>
          </div>
          <div className="col-sm-4">
            <ul className="list-unstyled">
              <p className="footer-p-tag">
                {t("footer.txt_14")}
                <br />
                {t("footer.txt_15")}
              </p>
              <a href="tel:+1 (844) 344-5277">+1 (844) 344-5277</a>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
